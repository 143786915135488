import React from 'react'
import Slider from "react-slick";
import styled from 'styled-components'

export default function NameSlider() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
    };

    const mobileSettings = {...settings, slidesToShow: 1}

    return (
        <>
            <DesktopSlider className='slider'>
                <Slider {...settings}>
                    <h1>詹姆斯·克利福德</h1>
                    <h1>James Clifford</h1>
                    <h1>जेम्स क्लिफोर्ड</h1>
                    <h1>جيمس كليفورد</h1>
                    <h1>ジェームズ・クリフォード</h1>
                    <h1>Джеймс Клиффорд</h1>
                </Slider>
            </DesktopSlider>
            <MobileSlider className='mobile-slider'>
                <Slider {...mobileSettings}>
                    <h1>James Clifford</h1>
                    <h1>詹姆斯·克利福德</h1>
                    <h1>जेम्स क्लिफोर्ड</h1>
                    <h1>جيمس كليفورد</h1>
                    <h1>ジェームズ・クリフォード</h1>
                    <h1>Джеймс Клиффорд</h1>
                </Slider>
            </MobileSlider>
        </>
    )
}

const MobileSlider = styled(Slider)`
    display: inherit; 
    @media all and (min-width: 420px) {
        display: none
    }
`

const DesktopSlider = styled(Slider)`
    display: none; 
    @media all and (min-width: 420px) {
        display: inherit
    }
`