import './App.css';
import {Route, withRouter} from 'react-router-dom'
import Landing from './components/Landing/Landing'
import Home from './components/Home/Home'

function App() {
  return (
    <div className="App">
      <Route 
        exact path="/"
        component={Landing}
      />
      <Route 
        path="/home"
        component={Home}
      />
    </div>
  );
}

export default withRouter(App);
