import React, {useState, useEffect, useCallback} from 'react'
import {Link} from 'react-router-dom'
import './Landing.css'
import NameSlider from '../Slider/Slider'
import styled from 'styled-components'; 

export default function Landing() {
    const [time, setTime] = useState("")

    const startTime = useCallback(() => {
        const today = new Date();
        let h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        m = checkTime(m);
        s = checkTime(s);
        setTime(`${h} : ${m} : ${s}`)
        setTimeout(startTime, 1000);
    }, [])
      
    function checkTime(i) {
        if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
        return i;
    }

    useEffect(() => {
        startTime()
    }, [startTime])
    
    return (
        <>
            <LandingContainer className='landing'>
                <NameSlider />
                <div className='circle'>
                    <Link to="/home"><span>Enter</span></Link>
                </div>
                <h2>Current Time: {time}</h2>
            </LandingContainer>
            <MobileLanding time={time}/>
        </>
    )
}

function MobileLanding(time) {
    
    return (
        <MobileContainer className='mobile-landing'>
            <NameSlider />
            <div className='mobile-circle'>
                <Link to="/home"><span>Enter</span></Link>
            </div>
            <h2>Current Time: {time.time}</h2>
        </MobileContainer>
    )
}

const LandingContainer = styled.div`
    display: none; 

    @media all and (min-width: 420px) {
        display: inherit; 
    }
`

const MobileContainer = styled.div`
    display: inherit; 

    @media all and (min-width: 420px) {
        display: none; 
    }
`
