import React, {useState} from 'react'
import './Home.css'
import { Link } from 'react-scroll'
import Header from './photos/000016530009.jpg'
import NameSlider from '../Slider/Slider.js'
import stockanalyzerimage from './photos/stock-analyzer-1.png'
import aqiimage from './photos/AQI-Lookup-Screenshot.png'

export default function Home() {
    return (
        <div className='home'>
            <NameSlider />
            <div className='nav'>
                <Link to="bio" smooth={true} duration={1000}>Bio</Link>
                <Link to="projects" smooth={true} duration={1000}>Projects</Link>
                <Link to="connect" smooth={true} duration={1000}>Connect</Link>
            </div>
            <div className='image'>
                <img src={Header} alt="JC header"/>
            </div>
            <section id="bio">
                <h1>Bio</h1>
                <p>
                Front end engineer with an interest in finance. Technologies I have professional experience with 
                include HTML, CSS, JavaScript, React.js (including React Hooks and React Testing Library), TypeScript, 
                Material UI, Styled Components, and Git. As a hobbyist, I possess a working knowledge of Node.js, 
                Express.js, and MySQL/MySQL Workbench. 

                I also have an interest in finance. I recently passed FINRA's Securities Industry Essentials exam 
                and enjoy furthering my knowledge of macroeconomics in my free time. Lastly, I know a thing or two 
                about music; my debut single has over 1,000,000 streams on Spotify, received FM radio play in hometown 
                (91X in San Diego), and has been featured on TV shows on CBS and Showtime. 
                </p>
            </section>
            <section id="projects">
                <h1>Projects</h1>
                <div className="project">
                    <h3>"Stock Analyzer"</h3>
                    <img src={stockanalyzerimage} alt="stock analyzer landing page"/>
                    <h4>Links:</h4>
                    <div className="link">
                        <h5>Live App: </h5><a href="https://stock-client-tau.vercel.app/" target="_blank" rel="noreferrer">https://stock-client-tau.vercel.app/</a>
                    </div>
                    <div className="link">
                        <h5>Client Repo: </h5><a href="https://github.com/Jamesclifford7/stock-client" target="_blank" rel="noreferrer">https://github.com/Jamesclifford7/stock-client</a>
                    </div>
                    <div className="link">
                        <h5>Server Repo: </h5><a href="https://github.com/Jamesclifford7/stock-server" target="_blank" rel="noreferrer">https://github.com/Jamesclifford7/stock-server</a>
                    </div>
                    <h4>Description:</h4>
                    <p>Simple web application that allows users to search for relevant stock information for investing purposes (price history, P/E ratio, etc.). Login with demo credentials or sign up to create an account in order to save individual stocks to a custom portfolio. I built this primarily because I wanted to make something that visualizes data and that stores user information in local storage.</p>
                    <h4>Technologies Used:</h4>
                    <p>Built with React.js and TypeScript for front end, and Node.js, Express.js, and MySQL/MySQL Workbench for back end.</p>
                </div>
                <div className="project">
                    <h3>"AQI Lookup"</h3>
                    <img src={aqiimage} alt="AQI landing page"/>
                    <h4>Links:</h4>
                    <div className="link">
                        <h5>Live App: </h5><a href="https://jamesclifford7.github.io/AQI-app/" target="_blank" rel="noreferrer">https://jamesclifford7.github.io/AQI-app/</a>
                    </div>
                    <div className="link">
                        <h5>Client Repo: </h5><a href="https://github.com/Jamesclifford7/AQI-app" target="_blank" rel="noreferrer">https://github.com/Jamesclifford7/AQI-app</a>
                    </div>
                    <h4>Description:</h4>
                    <p>Enter any U.S. City and State, and retrieve the current Air Quality Index and current temperature for that location. There is also a page that explains the Air Quality Index and a Contact page.</p>
                    <h4>Technologies Used:</h4>
                    <p>Built with HTML, CSS, and JavaScript (jQuery).</p>
                </div>

            </section>
            <section id="connect">
                <h1>Connect</h1>
                <h3>Drop me a line!</h3>
                <div className='connect-container'>
                    <div className='email'>
                        <Email />
                    </div>
                    <div className='linkedin'>
                        <LinkedIn />
                    </div>
                    <div className='github'>
                        <GitHub />
                    </div>
                    <div className='spotify'>
                        <Spotify />
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

function Email() {
    const [isShowing, setIsShowing] = useState(false)

    return (
        isShowing ? <h4 onMouseLeave={() => setIsShowing(false)}><a href="mailto:jamesclifforddev@gmail.com">jamesclifforddev@gmail.com</a></h4> : <h4 onMouseEnter={() => setIsShowing(true)}>Email</h4>
    )
}

function LinkedIn() {
    const [isShowing, setIsShowing] = useState(false)
    
    return (
        isShowing ? <h4 onMouseLeave={() => setIsShowing(false)}><a href="https://www.linkedin.com/in/james-clifford-developer/" target="_blank" rel="noreferrer">https://www.linkedin.com/in/james-clifford-developer/</a></h4> : <h4 onMouseEnter={() => setIsShowing(true)}>LinkedIn</h4>
    )
}

function GitHub() {
    const [isShowing, setIsShowing] = useState(false)
    
    return (
        isShowing ? <h4 onMouseLeave={() => setIsShowing(false)}><a href="https://github.com/Jamesclifford7" target="_blank" rel="noreferrer">https://github.com/Jamesclifford7</a></h4> : <h4 onMouseEnter={() => setIsShowing(true)}>GitHub</h4>
    )
}

function Spotify() {
    const [isShowing, setIsShowing] = useState(false)
    
    return (
        isShowing ? <h4 onMouseLeave={() => setIsShowing(false)}><a href="https://open.spotify.com/artist/21FxRVEbUGwNolaTly31PQ?si=2tb3lqEQSN6_kSMNyqpJVw" target="_blank" rel="noreferrer">https://open.spotify.com/artist/21FxRVEbUGwNolaTly31PQ?si=2tb3lqEQSN6_kSMNyqpJVw</a></h4> : <h4 onMouseEnter={() => setIsShowing(true)}>My music on Spotify (from a past life)</h4>
    )
}